export const CONSTANTS = {
  /* API_URL: process.env.REACT_APP_API_URL,
    DEBUG: process.env.REACT_APP_DEBUG,  */
  /* API_URL: 'http://helpline_api.test/', */
  //Testing
  API_URL: "https://test.hlapi.dynamia.cn/",
  //API_URL: 'http://127.0.0.1:8000/',
  DEBUG: "staging",
  //Production
  /*API_URL: 'https://api.helpline.dynamia.app/',
  DEBUG: 'production',*/
};
